import { Injectable } from '@angular/core';
import { DebugConsole } from "../../helpers/debug-console";
import { NotificationEventService } from "../core/notification.service";
import { NotificationEvent } from "../../models/notification-event";
import { Observable } from "rxjs/Observable";
import { ApiService } from "../data/api.service";
import { AuthService } from "../data/auth.service";

@Injectable()
export class SettingsService {

    initialized = false;
    cachedSettings = [];
    sessionSettings = [];
    constructor(private apiService: ApiService, private authService: AuthService, private notificationService: NotificationEventService) {
        // download the setting for this user
        DebugConsole.log("Init Setting Service");
        this.notificationService.subscribeToNotificationEvent('current_user_changed', this, this.handleUserChange);
        this.handleUserChange(this, null);
    }

    private handleUserChange(scope, data: NotificationEvent) {
        DebugConsole.log("UserChanged");
        if (scope.authService.getLoggedInUser()) {
            scope.apiService.get("/user/config").subscribe(res => {
                DebugConsole.log(res);
                if (res.success) {
                    scope.cachedSettings = res.response;
                }
            });
        }
    }


    hasLocalSetting(settingKey) {
        return !!(sessionStorage.getItem(settingKey) || localStorage.getItem(settingKey));
    }

    getLocalSetting(settingKey) {
        return sessionStorage.getItem(settingKey) || localStorage.getItem(settingKey);
    }
    getAllLocalSettings(settingKey) {
        let filteredSettings = [];
        for (let prop in sessionStorage) {
            if (prop.startsWith(settingKey)) {
                filteredSettings[prop] = sessionStorage[prop];
            }
        }
        //console.log(filteredSettings);
        return filteredSettings;
    }

    setLocalSetting(settingKey, permanent, value = 'true') {
        sessionStorage.setItem(settingKey, value);

        if (permanent) {
            localStorage.setItem(settingKey, value);
        }
    }

    removeLocalSetting(settingKey, permanent) {
        sessionStorage.removeItem(settingKey);
        if (permanent)
            localStorage.removeItem(settingKey);
    }

    asyncUserHasSetting(userId, settingKey) {
        if (this.syncUserHasSetting(userId, settingKey)) {

            return new Observable((observer) => {
                observer.next(true);
                observer.complete();
            });
        }
        else {
            return this.apiService.get("/user/config").map(response => {
                let resp = JSON.parse(JSON.stringify(response));
                if (resp.success) {
                    this.cachedSettings = resp.response;
                    let valid = !!(this.cachedSettings && (this.cachedSettings.findIndex(x => x.name == settingKey) >= 0));
                    return valid;
                }
            });
        }
    }

    syncUserHasSetting(userId, settingKey): boolean {
        let valid = !!(this.cachedSettings && (this.cachedSettings.findIndex(x => x.name == settingKey) >= 0));
        let sessionValid = !!(this.sessionSettings && (this.sessionSettings.findIndex(x => x.name == settingKey) >= 0));
        return valid || sessionValid;
    }

    removeSettingForUser(userId, settingKey, permanent) {
        if (permanent) {
            let data = {
                key: settingKey
            };
            this.apiService.post("/user/config/remove", data).subscribe(response => {
                let responseObj = JSON.parse(JSON.stringify(response));
                if (responseObj.success) {
                    //this.cachedSettings[settingKey] =
                    // remove the setting
                    const index = this.cachedSettings.indexOf(settingKey);
                    if (index !== -1) {
                        this.cachedSettings.splice(index, 1);
                    }
                    const indexSess = this.sessionSettings.indexOf(settingKey);
                    if (indexSess !== -1) {
                        this.sessionSettings.splice(indexSess, 1);
                    }
                }
            });
        }
        else {
            const indexSess = this.sessionSettings.indexOf(settingKey);
            if (indexSess !== -1) {
                this.sessionSettings.splice(indexSess, 1);
            }
        }
    }

    setSettingForUser(userId, settingKey, permanent, value = "true") {
        DebugConsole.log("SetSettingForUser", userId, settingKey, permanent, value);
        let mapSettingUser = this.setSettingForUserObserver(userId, settingKey, permanent, value);
        if (mapSettingUser)
            mapSettingUser.subscribe();
    }

    setSettingForUserObserver(userId, settingKey, permanent, value) {
        if (permanent) {
            let data = {
                key: settingKey,
                value: value
            };

            return this.apiService.post("/user/config", data).map(response => {
                let responseObj = JSON.parse(JSON.stringify(response));
                if (responseObj.success) {
                    //this.cachedSettings[settingKey] =
                    // remove the setting
                    this.cachedSettings.push({
                        description: value,
                        name: settingKey,
                        user_id: userId
                    });
                }
            });
        }
        else {
            this.sessionSettings.push({
                description: value,
                name: settingKey,
                user_id: userId
            });

            // console.log("SYNC SETTINGS ", this.sessionSettings);
        }
    }

    getSettingForUser(userId, settingKey) {
        return this.apiService.get("/user/config").map(response => {
            let resp = JSON.parse(JSON.stringify(response));
            if (resp.success) {
                this.cachedSettings = resp.response;
                let val = null;
                if (this.cachedSettings) {
                    let index = this.cachedSettings.findIndex(x => x.name == settingKey);
                    if (index >= 0) {
                        val = this.cachedSettings[index];
                    }
                }
                return val;
            }
        });

    }
}