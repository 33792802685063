import {isDevMode} from "@angular/core";

export class DebugConsole {
	static log(...args: any[]){
		if(isDevMode()){
			let callerName;
			try { throw new Error(); }
			catch (e) {
				let re = /(\w+)@|at (\w+) \(/g, st = e.stack, m;
				let caller = e.stack.split("\n");
				re.exec(st), m = re.exec(st);
				callerName = caller[2];
			}
			console.log(args.slice(), callerName);
		}
	}


}
