// TODO: honey

import { Injectable } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { NotificationEvent } from "../../models/notification-event";


@Injectable()
export class NotificationEventService {


	private eventMap = new Map<string , Map<any, Subject<NotificationEvent>>>();
	constructor() {	}

	sendNotificationEvent(notificationEvent : NotificationEvent){
		let sourceEventMap = this.getListEventNotification(notificationEvent.name);
		if(sourceEventMap){
			sourceEventMap.forEach(value => {
				value.next(notificationEvent);
			})
		}
	}

	subscribeToNotificationEvent(name : string, scope : any,  x: (scope, data) => void){

		let subject = this.getOrCreateEventNotification(name, scope);
		if(subject.observers.length == 0)
			subject.subscribe( resp => {
				x(scope, resp)
			}
		);
	}

	unsubscribeToNotificationEvent(name : string, scope :any){
		let scopeSubject = this.getOrCreateEventNotification(name, scope);
		scopeSubject.unsubscribe();
		this.removeEventNotification(name, scope);
	}

	private getOrCreateEventNotification(name :string, scope: any){

		let eMap = this.getListEventNotification(name);
		if(!eMap.has(scope)){
			let scopeSubject = new Subject<NotificationEvent>();
			eMap.set(scope, scopeSubject);
		}

		return eMap.get(scope);
	}

	private removeEventNotification(name:string, scope:any){
		let eMap = this.getListEventNotification(name);
		if(eMap.has(scope)){
			eMap.delete(scope);
		}
	}

	private getListEventNotification(name :string){
		if(!this.eventMap.has(name)){
			this.eventMap.set(name, new Map<any, Subject<NotificationEvent>>());
		}

		return this.eventMap.get(name);
	}
}