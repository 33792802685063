import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagFilter'
})
export class TagFilterPipe implements PipeTransform {

  transform(items: any, searchText:string): any {
    //console.log('TagFilterPipe()');
    if(!items) return []; //return nothing if array is missing
    if(!searchText) return items; //return all items if nothing to filter

    searchText = searchText.toLowerCase();

    //return all items that match the search text
    return items.filter(item =>{
      return item.name.toLowerCase().includes(searchText);
    });
  }

}