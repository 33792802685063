<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-6">
        <h3>Uploaded Result List</h3>
        <small>
          <a class="btn btn-secondary" routerLink="/upload-add">Add</a>
        </small>
      </div>
      <div class="col-lg-6">
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item"><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i></a></li>
        </ol>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends -->

<div class="container-fluid">
  <div class="row">

    <div class="col-sm-12">
      <div class="card">

        <div class="card-body">
          <div class="table-responsive">
            <table class="table">

              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Title</th>
                  <th scope="col">Time</th>
                  <th scope="col">Tags</th>
                  <th scope="col">Upload Date</th>
                  <th scope="col">Overall Score</th>
                  <th scope="col">Voice</th>
                  <th scope="col">Gesture</th>
                  <th scope="col">Posture</th>
                  <th scope="col">Volume</th>
                  <th scope="col">Pace</th>
                  <th scope="col">Filler Words</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let result of results | sortBy:'desc':'id'">
                  <td> {{result.result_id}} </td>
                  <td> {{result.title}} </td>
                  <td> {{result.length}} </td>
                  <td> {{result.tags}} </td>
                  <td> {{result.date}} </td>
                  <td> {{result.overall_score}} </td>
                  <td> {{result.voice_overall_score}} </td>
                  <td> {{result.gesture_overall_score}} </td>
                  <td> {{result.posture_overall_score}} </td>
                  <td> {{result.volume_score}} </td>
                  <td> {{result.pace_score}} </td>
                  <td> {{result.filler_word_score}} </td>
                  <td>
                    <div class="row">
                      <div class="dropdown">
                        <button class="btn btn-secondary btn btn-block dropdown-toggle" ngbDropdown
                          placement="bottom-center" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          Actions
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <!-- <a class="dropdown-item" type="button" (click)="openReplayModal(result)">Play
                            Video/Audio</a> -->
                          <!-- <a class="dropdown-item" type="button" (click)="delete(result)">Delete</a> -->
                          <a class="dropdown-item" type="button" (click)="transcriptModal(result)">View Transcript</a>
                          <a class="dropdown-item" type="button" (click)="rerunJob(result.recording_id, result.title)">Rerun Job</a>
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="results.length > 0">
        <button class="btn btn-primary" (click)="downloadResults()">Download CSV</button>
      </div>
    </div>
  </div>
</div>
