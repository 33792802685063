<div class="container-fluid">
  <!--login page start-->
  <div class="authentication-main">
      <div class="row">
          <div class="col-md-4 p-0">
              <div class="auth-innerleft">
                  <div class="text-center">
                      <img src="assets/images/Presentr-{{logoColor}}.png" class="logo-login" alt="">
                      <hr>
                  </div>
              </div>
          </div>
          <div class="col-md-8 p-0">
              <div class="auth-innerright">
                  <div class="authentication-box">
                      <h4>LOGIN</h4>
                      <h6>Enter your Presentr Email and Password For Login</h6>
                      <div *ngIf="error" class='row mt-5'>
                        <div [innerHTML]="error" class="alert alert-danger col-12" role="alert">
                        </div>
                    </div>
        
        
                      <div class="card mt-4 p-4 mb-0">
                          <form class="theme-form" novalidate (ngSubmit)="f.form.valid && login()"
                          #f="ngForm">
                          <div class="form-group">
                            <label class="col-form-label pt-0">Email</label>
                            <input type="email" name="username" [(ngModel)]="model.username" #username="ngModel" [email]="true" required placeholder="Email"  class="form-control form-control-lg" required>
                            <div *ngIf="f.submitted && !username.valid" class="help-block">A valid email address is required.</div>

                          </div>

                          <div class="form-group">
                            <label class="col-form-label pt-0">Password</label>
                            <input type="password" name="password" [(ngModel)]="model.password" #password="ngModel" required placeholder="Password"  class="form-control form-control-lg" required>
                            <div *ngIf="f.submitted && !password.valid" class="help-block">A valid password is required.</div>

                          </div>
                    
                              <div class="form-group form-row mt-3 mb-0">
                                  <div class="col-md-3">
                                      <button [disabled]="loading" type="submit" class="btn btn-secondary">LOGIN</button>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--login page end-->
</div>