<div *ngIf="job && job.status">

  <h3 class="p-5">Job status: {{ job.status }}</h3>

  <table class="table border">
    <tr class="border">
      <th>Pending</th>
      <th>Failed</th>
      <th>Completed</th>
    </tr>
    <tr>
      <td>{{ job.count.pending }}</td>
      <td>{{ job.count.failed }}</td>
      <td>{{ job.count.completed }}</td>
    </tr>
  </table>

  <div class="progress mt-2">
    <div class="progress-bar progress-bar-striped {{job.status == 'pending' ? 'progress-bar-animated' : ''}}
              {{job.status == 'success' ? 'bg-success' : ''}}
              {{job.status == 'failed' ? 'bg-danger' : ''}}"
         role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 0"
         id="progress-bar">
    </div>
  </div>

  <div class="mt-2">
    <button class="btn btn-secondary" *ngIf="job.count.pending > 0" (click)="refreshStatus()">Refresh</button>
    <button class="btn btn-primary" *ngIf="job.count.pending == 0 && redirectTo" (click)="moveForward()">Acknowledge</button>
  </div>
</div>
