import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from "../services/data/auth.service";
import {RolesEnum} from "../enums/user-role.enum";
import { HasPermissionPipe } from '../pipes/has-permisison.pipe';

@Injectable()
export class AdminGuard implements CanActivate {

	constructor(private hasPermissionPipe:HasPermissionPipe, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		if (localStorage.getItem('currentUser')) {
			if(this.hasPermissionPipe.transform('IsAdmin'))
				return true;
		}

		// not logged in so redirect to login page with the return url
		this.router.navigate([''], { queryParams: { returnUrl: state.url }});
		return false;
	}
}