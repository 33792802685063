import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { EnvironmentAPIs } from "../../enums/environment-apis.enum";

@Injectable()
export class ApiService {
  private actionUrl: string;
	private hasShownDnsFailoverAlert: Boolean = false;
  constructor(private http: HttpClient, private router: Router) {
    this.actionUrl = environment.ketchupUrl; // will be overwritten in most cases with setupActionURl
  }

  public post<T>(
    path: string,
    object: any,
    enviorment?: EnvironmentAPIs,
    header_values?: HttpHeaders
  ): Observable<T> {
    this.setupActionUrl(enviorment);
    return this.http
      .post<T>(this.actionUrl + path, object, { headers: header_values })
      .map(response => {
        this.logoutOn401s(response);
        return response;
      });
  }

  public get<T>(
    path: string,
    enviorment?: EnvironmentAPIs,
    header_values?: HttpHeaders
  ): Observable<T> {
    this.setupActionUrl(enviorment);

    return this.http
      .get<T>(this.actionUrl + path, { headers: header_values })
      .map(response => {
        this.logoutOn401s(response);
        return response;
      });
  }

  public put<T>(
    path: string,
    object: any,
    header_values?: HttpHeaders
  ): Observable<T> {
    return this.http
      .put<T>(this.actionUrl + path, object, { headers: header_values })
      .map(response => {
        this.logoutOn401s(response);
        return response;
      });
  }

  public delete<T>(path: string, header_values?: HttpHeaders): Observable<T> {
    return this.http
      .delete<T>(this.actionUrl + path, { headers: header_values })
      .map(response => {
        this.logoutOn401s(response);
        return response;
      });
  }

  private setupActionUrl(envAPI: EnvironmentAPIs) {
    switch (envAPI) {
      case EnvironmentAPIs.Ketchup:
        this.actionUrl = environment.ketchupUrl;
        break;
      case EnvironmentAPIs.BBQ:
        this.actionUrl = environment.bbqUrl;
        break;
      default:
        this.actionUrl = environment.ketchupUrl;
        break;
    }
  }

  private logoutOn401s(response) {
    // login successful if there's a jwt token in the response
    let responseObj = JSON.parse(JSON.stringify(response));
    if (
      !responseObj.success &&
      (responseObj.code == 401 || responseObj.code == 498)
    ) {
      if (!this.hasShownDnsFailoverAlert &&
        responseObj.options &&
        responseObj.options.location &&
        responseObj.options.location === "dns-failover"
      ) {
		  this.hasShownDnsFailoverAlert = true;
        alert(responseObj.response.error);
      }

      this.router.navigate(["logout"]);
    } else {
		this.hasShownDnsFailoverAlert = false;
		// Reset the alert incase the API comes back online and then goes off again
	}
  }
}
