import { Component, OnInit, isDevMode } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logoColor = isDevMode() ? "RED" : "BLUE";

  constructor() { }

  ngOnInit() {
  	 $.getScript('assets/js/script.js');
  }

}
