import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {Read} from '../../models/read';
import { Topic } from '../../models/topic';
import { TopicPrompt } from '../../models/topic-prompt';
import {AuthService} from './auth.service';
import { TextStore } from '../../models/text-store';
import { Watch } from '../../models/watch';
import { Tip } from '../../models/tip';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContentService {

    constructor(private apiService: ApiService, private authService: AuthService) {
    }

    getSignedContentPath(type, typeId){
        return environment.ketchupUrl + '/content/signed?type='+type+'&id=' + typeId + '&token=' + this.authService.getToken();
    }

    getTypes(){
        return this.apiService.get('/content/admin/tip-types').map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        );
    }

    getRanges(){
        return this.apiService.get('/content/admin/tip-ranges').map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        );
    }
    getWatches(id: Number = null) {
        let queryParams = '';
        if (id) {
            queryParams = '?id=' + id;
        }

        return this.apiService.get('/content/video-tips' + queryParams).map(
            (response) => {
                let resp = JSON.parse(JSON.stringify(response));
                let translatedResp = [];
                if (resp.success) {
                    resp.response.forEach((val) => {

                        let b = <Watch> val;
                        translatedResp.push(b);
                    });

                    resp.response = translatedResp;
                }
                return resp;
            }
        );
    }

    getTips(id: Number = null) {
        let queryParams = '';
        if (id) {
            queryParams = '?id=' + id;
        }

        return this.apiService.get('/content/tips' + queryParams).map(
            (response) => {
                let resp = JSON.parse(JSON.stringify(response));
                let translatedResp = [];
                if (resp.success) {
                    resp.response.forEach((val) => {
                        let b = <Tip> val;
                        translatedResp.push(b);
                    });
                    resp.response = translatedResp;
                }

                return resp;
            }
        );
    }

    createWatch(video: Watch) {
        return this.apiService.post('/content/video-tip', video).map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        );
    }

    updateWatch(video: Watch) {
        return this.apiService.post('/content/video-tip/update', video).map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        );
    }



    getReads(id: Number = null) {
        let queryParams = '';
        if (id) {
            queryParams = '?id=' + id;
        }
        return this.apiService.get('/content/blogs' + queryParams).map(
            (response) => {
                let resp = JSON.parse(JSON.stringify(response));
                let translatedResp = [];
                if (resp.success) {
                    resp.response.forEach((val) => {
                        let b = <Read> val;
                        translatedResp.push(b);
                    });
                    resp.response = translatedResp;
                }

                return resp;
            }
        );
    }


createRead(read: Read) {
    return this.apiService.post('/content/blog', read).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

updateRead(read: Read) {
    return this.apiService.post('/content/blog/update', read).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

createTip(tip: Tip) {
    return this.apiService.post('/content/tip', tip).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

updateTip(tip: Tip) {
    return this.apiService.post('/content/tip/update', tip).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}


createTopic(topic: Topic) {
    return this.apiService.post('/content/admin/topic', topic).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

updateTopic(topic: Topic) {
    return this.apiService.post('/content/admin/topic/update', topic).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

createTopicPrompt(topic: TopicPrompt) {
    return this.apiService.post('/content/admin/topic/prompt', topic).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

updateTopicPrompt(topic: TopicPrompt) {
    return this.apiService.post('/content/admin/topic/prompt/update', topic).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

getAllTopics(id: Number = null) {
    let queryParams = '';
    if (id) {
      queryParams += '?id=' + id;
    }
    return this.apiService.get('/content/admin/topics' + queryParams).map(
        (response) => {
            let resp = JSON.parse(JSON.stringify(response));
            let translatedResp = [];
            if (resp.success) {
                resp.response.forEach((val) => {
                    let b = <Topic> val;
                    translatedResp.push(b);
                });
                resp.response = translatedResp;
            }

            return resp;
        }
    );
}

getAllTextStoreRows(id: Number = null) {
    const queryParams = {};
    if (id) {
        queryParams['id'] = id;
    }
    return this.apiService.post('/content/text-store', queryParams).map(
        (response) => {
            let resp = JSON.parse(JSON.stringify(response));
            let translatedResp = [];
            if (resp.success) {
                resp.response.forEach((val) => {
                    let b = <TextStore> val;
                    translatedResp.push(b);
                });
                resp.response = translatedResp;
            }

            return resp;
        }
    );
}

createTextStoreRow(textStore: TextStore) {
    return this.apiService.post('/content/admin/text-store/add', textStore).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

updateTextStoreRow(textStore: TextStore) {
    return this.apiService.post('/content/admin/text-store/update', textStore).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

uploadRecording(recordingModel: any){
    return this.apiService.post('/content/uploadFile', recordingModel).map(
        (response) => {
            return JSON.parse(JSON.stringify(response));
        }
    );
}

// startRecordProcessing(id){
//     return this.apiService.post('/content/processRecording', id).map(
//         (response) => {
//             return JSON.parse(JSON.stringify(response));
//         }
//     );
}

