// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  headTitle?: string,
  badgeType?: string;
  badgeValue?: string;
  children?: Menu[];
}

export const MENUITEMS: Menu[] = [
  {
    headTitle: 'General'
  },
  {
    path: '/', title: 'Dashboard', icon: 'icon-desktop', type: 'link'
  },
  {
    headTitle: 'Reports'
  },
  {
    path: '/reports/daily-activity-report', title: 'Daily Activity Report', icon: 'icon-dashboard', type: 'link'
  },
  {
    path: '/reports/user-stats', title: 'User stats', icon: 'icon-dashboard', type: 'link'
  },
  {
    path: '/reports/users-report', title: 'User Report', icon: 'icon-dashboard', type: 'link'
  },
  {
    path: '/reports/usage-report', title: 'Usage Reporting', icon: 'icon-dashboard', type: 'link'
  },
  {
    headTitle: 'Customers'
  },
  {
    path: '/customer/search', title: 'Search', icon: 'icon-search', type: 'link'
  },
  {
    path: '/customer/add', title: 'Add Customer', icon: 'icon-plus', type: 'link'
  },
  {
    headTitle: 'Users'
  },
  {
    path: '/user/search', title: 'Search', icon: 'icon-search', type: 'link'
  },
  {
    headTitle: 'Content'
  },
  {
    path: '/content/tip', title: 'Tips', icon: 'icon-light-bulb', type: 'link'
  },
  {
    path: '/content/text-store', title: 'Text Store', icon: 'icon-text', type: 'link'
  },
  {
    path: '/content/topics', title: 'Topics', icon: 'icon-files', type: 'link'
  },
  {
    path: '/content/watch', title: 'Watch', icon: 'icon-eye', type: 'link'
  },
  {
    path: '/content/read', title: 'Read', icon: 'icon-book', type: 'link'
  },
  {
    path: '/content/ignored-words', title: 'Ignored Words', icon: 'icon-eraser', type: 'link'
  },
  {
    headTitle: 'Events'
  },{
    path: '/reports/customer-results-report', title: 'Results Report', icon: 'icon-dashboard', type: 'link'
  },
  {
    path: '/reports/weighted-results-report', title: 'Weighted Results Report', icon: 'icon-dashboard', type: 'link'
  },
  {
    path: '/event/search', title: 'Search', icon: 'icon-search', type: 'link'
  },
  {
    path: '/event/advanced-search', title: 'Advanced Search', icon: 'icon-search', type: 'link'
  },
  {
    path: '/event/add', title: 'Add Event', icon: 'icon-plus', type: 'link'
  },
  // {
  //   headTitle: 'Licenses'
  // },
  // {
  //   path: '/pages/stater-kit', title: 'Dashboard', icon: 'icon-desktop', type: 'link'
  // },

  {
    headTitle: 'Results'
  },
  {
    path: '/results/search', title: 'Search', icon: 'icon-desktop', type: 'link'
  },
  // {
  //   title: 'Starter Kit', icon: 'icon-anchor', type: 'sub', children: [{
  //       title: 'Bootstrap', type: 'sub', children: [
  //         { path: '/base/accordion', title: 'Accordion', type: 'link' },
  //         { path: '/base/alert', title: 'Alert', type: 'link' },
  //         { path: '/base/dropdown', title: 'Dropdown', type: 'link' },
  //         { path: '/base/grid', title: 'Grid', type: 'link' },
  //         { path: '/base/helper-class', title: 'Helper Classes', type: 'link' },
  //         { path: '/base/list', title: 'List', type: 'link' },
  //         { path: '/base/modal', title: 'Modal', type: 'link' },
  //         { path: '/base/navs', title: 'Navs', type: 'link' },
  //         { path: '/base/popover', title: 'Popover', type: 'link' },
  //         { path: '/base/progressbar', title: 'Progresssbar', type: 'link' },
  //         { path: '/base/shadow', title: 'Shadow', type: 'link' },
  //         { path: '/base/spinners', title: 'Spinners', type: 'link' },
  //         { path: '/base/state-color', title: 'State Color', type: 'link' },
  //         { path: '/base/tabs', title: 'Tabs', type: 'link' },
  //         { path: '/base/tag-n-pills', title: 'Tag & pills', type: 'link' },
  //         { path: '/base/typography', title: 'Typography', type: 'link' }
  //       ]
  //     },
  //     {
  //       title: 'Ng-Bootstrap', type: 'sub', children: [
  //         { path: '/base/ngb/accordion', title: 'Accordion', type: 'link' },
  //         { path: '/base/ngb/alert', title: 'Alert', type: 'link' },
  //         { path: '/base/ngb/buttons', title: 'Buttons', type: 'link' },
  //         { path: '/base/ngb/carousel', title: 'Carousel', type: 'link' },
  //         { path: '/base/ngb/collapse', title: 'Collapse', type: 'link' },
  //         { path: '/base/ngb/datepicker', title: 'Datepicker', type: 'link' },
  //         { path: '/base/ngb/dropdown', title: 'Dropdown', type: 'link' },
  //         { path: '/base/ngb/modal', title: 'Modal', type: 'link' },
  //         { path: '/base/ngb/pagination', title: 'Pagination', type: 'link' },
  //         { path: '/base/ngb/popover', title: 'Popover', type: 'link' },
  //         { path: '/base/ngb/progressbar', title: 'Progressbar', type: 'link' },
  //         { path: '/base/ngb/rating', title: 'Rating', type: 'link' },
  //         { path: '/base/ngb/tabset', title: 'Tabset', type: 'link' },
  //         { path: '/base/ngb/timepicker', title: 'Timepicker', type: 'link' },
  //         { path: '/base/ngb/tooltip', title: 'Tooltip', type: 'link' },
  //         { path: '/base/ngb/typeahead', title: 'Typeahead', type: 'link' }
  //       ]
  //     }
  //   ]},

  {
    headTitle: 'Legacy'
  },
  {
    path: '/legacy/licenses', title: 'Legacy Licenses', icon: 'icon-desktop', type: 'link'
  },
  {
    headTitle: 'Add Ons'
  },
  {
    path: '/add-ons/leader-board', title: 'Leader Board', icon: 'icon-dashboard', type: 'link'
  },
  {
    headTitle: 'Upload'
  },
  {
    path: '/upload-data', title: 'Upload Data', icon: 'icon-dashboard', type: 'link'
  }
]
