import { Injectable, Injector} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/data/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    
    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const authService: AuthService = this.injector.get(AuthService);
        var token = authService.getToken();
        if(token)
        {
	        const changedReq = req.clone({headers: req.headers.set('Content-Type', 'application/json').set('Accept', 'application/json').set('Authorization', 'Bearer ' + token)});
	        return next.handle(changedReq);
        }
        else{
	        const changedReq = req.clone({headers: req.headers.set('Content-Type', 'application/json').set('Accept', 'application/json')});
	        return next.handle(changedReq);
        }

    }
}