<div class="container-fluid">
  <div class="page-header">
    <div class="row">

      <div class="col-lg-6">
        <h1>Add Video for Scoring</h1>
      </div>
      <div class="col-lg-6">
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard'"><i class="fa fa-home"></i></a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="'/upload-data'">Cancel</a>
          </li>
        </ol>
      </div>
    </div>
  </div>

  <div *ngIf="uploadComplete" class="col-md-12 upload-success">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">{{successTitle}}</h3>
        <p>
          {{successDescription}}
        </p>
      </div>

    </div>
  </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <!-- <div class="form-group"> -->
              <div class="input-group">
                <h2>Tags</h2>
                <input type="text" id="tags" [(ngModel)]="searchText" name="searchText" class="form-control search-box"
                  placeholder="Search Tags or Add New Tag">
                <div class="input-group-btn" *ngIf="searchText.length > 0">
                  <button class="btn btn-default">
                    <span class="fa fa-times" (click)="clearFilter()"></span>
                  </button>
                  <button class="btn btn-default" (click)="addTag()">
                    <span class="fa fa-plus"></span> Add Tag
                  </button>
                </div>
              </div>

              <ul class="tags-list">
                <li *ngFor="let t of tags | tagFilter: searchText; let i=index" class="tag-item">
                  <label class="form-label" for="{{i}}">
                    <input (click)="selectItem($event, t)" type="checkbox" id="{{i}}" name="tag"
                      [checked]="t.selected" />
                    <span class="tag-text">{{t.name}}</span>
                  </label>
                </li>
              </ul>
              <button *ngIf="selectedTags.length > 0" (click)="clearSelection()" class="btn btn-large btn-primary">Clear
                Selection</button>
            </div>
            <div class="col-sm-12">
              <h2>Select a file from your computer and click upload.</h2>
              <div *ngIf="showError" class="alert alert-danger" role="alert">{{errorMessage}}</div>
              <div class="options">

                <div>
                  <label>
                    <input type="radio" value="0" name="fileFormat" (click)="fileFormat=0" /> Video file <br />
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" value="1" name="fileFormat" (click)="fileFormat=1" /> Audio file
                  </label>
                </div>
                <!-- <div>
                  <label>
                    <input type="radio" value="2" name="fileFormat" (click)="fileFormat=2"> URL link
                  </label>
                </div> -->
              </div>
              <div class="select-file">
                <input *ngIf="fileFormat==0" type="file" class="form-control form-control-lg"
                  accept=".webm,.mpeg,.mov,.avi,.mpg" (change)="selectFile($event)" #fileInput>
                <input *ngIf="fileFormat==1" type="file" class="form-control form-control-lg"
                  accept=".wav,.mp3,.flac,.ogg" (change)="selectFile($event)" #fileInput>
                <!-- <input type="url" *ngIf="fileFormat==2" class="form-control form-control-lg" name="videourl"
                  id="videourl"> -->
              </div>
            </div>
            <div class="col-sm-12">
              <h2>Title:</h2>
              <input type="text" id="title" name="title" class="form-control" [(ngModel)]="fileTitle"
                placeholder="Title">
            </div>
            <div class="col-sm-12">
              <h2>Recording Date:</h2>
              <input type="recordingDate" class="filter-input" placeholder="Original Recording Date"
                [(ngModel)]="recordingDate" name="recordingDate">
            </div>
            <!-- <div class="form-group">
                  <label for="cutaways" class="col-form-label pt-0">Cutaways in video:</label>
                  <select class="form-control btn-pill digits">
                    <option value='2'>Unsure</option>
                    <option value='1'>Yes</option>
                    <option value='0'>No, video only on speaker</option>
                  </select>
                </div>
                 <div class="form-group">
                  <label for="stance" class="col-form-label pt-0">Speaker sitting or standing:</label>
                  <select class="form-control btn-pill digits" name="stance">
                    <option value='2'>Unsure</option>
                    <option value='1'>Standing</option>
                    <option value='0'>Sitting</option>
                  </select>
                </div> -->
            <div class="col-sm-12">
              <button class="btn btn-success" [disabled]="!selectedFile || processing"
                (click)="upload()">Upload</button>
              <span *ngIf="processing" class="pull-right spinner fa fa-cog fa-spin fa-2x"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
