<!--Page Header Start-->
<div class="page-main-header">
    <div class="main-header-left">
        <div class="logo-wrapper">
            <a [routerLink]="'/'">
                <img src="assets/images/Presentr-{{logoColor}}.png" alt=""/>
            </a>
        </div>
    </div>
    <div class="main-header-right row">
        <div class="mobile-sidebar" id="mobile-sidebar">
            <div class="media-body text-right switch-sm">
                <label class="switch">
                    <input type="checkbox" id="sidebar-toggle" checked>
                    <span class="switch-state"></span>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus">
                <li>
                    <a href="javascript:void(0)" toggleFullscreen class="text-dark">
                        <img class="align-self-center pull-right mr-2" src="assets/images/dashboard/browser.png" alt="header-browser">
                    </a>
                </li>
            
            
                <li class="onhover-dropdown">
                    <div class="media  align-items-center">
                        <img class="align-self-center pull-right mr-2" src="assets/images/dashboard/user.png" alt="header-user"/>
                        <div class="media-body">
                            <h6 class="m-0 txt-dark f-16">
                                My Account
                                <i class="fa fa-angle-down pull-right ml-2"></i>
                            </h6>
                        </div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20">
                        <li>
                            <a routerLink="/logout">
                                <i class="icon-power-off"></i>
                                Logout
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle">
                <i class="icon-more"></i>
            </div>
        </div>
    </div>
</div>
<!--Page Header Ends-->

