import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../classes/services/data/api.service';
import { ContentService } from '../../../classes/services/data/content.service';
import { ExportToCsv } from 'export-to-csv';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBasic } from './modal-basic';
import { Video } from 'aws-sdk/clients/rekognition';
import { title } from 'process';
// import {MediaPopupModalComponent} from '../upload-data/media-popup-modal.component';




@Component({
  selector: 'app-upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss']

})
export class UploadDataComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private contentService: ContentService,
    // private popupService: PopupNotificationService
  ) {
  }

  closeResult = '';
  results: any = [];
  fileName: any = [];
  recording: any = [];
  transcript: any = [];
  x: any[];
  model: any = {
  };


  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.apiService.get('/admin/insights/user/voice').subscribe(data => {
      const response = JSON.parse(JSON.stringify(data)).response;
      this.results = response;
    });

  }

  rerunJob(id, title) {
    if (confirm("Are you sure you want to rerun the process for " + title + "?")) 
    {
       this.apiService.post('/content/restartTartarJobs/'+id, null).subscribe(
       (response) => {
        alert("Action Complete")
        return JSON.parse(JSON.stringify(response))
        }
       )}
  }




  // openReplayModal(result) {
  //   console.log(result.video_path);
  //   // this.modalService.open(result.recording);
  //   this.popupService.open(MediaPopupModalComponent, result.recording);
  // }

  // delete(result) {
  // console.log("delete")
  // this.popupService.openPopup(ConfirmationPopupModalComponent, {
  //     title: 'Delete Recording',
  //     elementRef: this.confirmDeleteRecordingElement
  // }, (result) => {
  //     if (result) {
  //         let ret = this.resultService.deleteRecordingAndTranscript(this.result.id)
  //             .subscribe(resp => {
  //                 if (resp.success) {
  //                     this.result.recording.audio_path = null;
  //                     this.result.recording.video_path = null;
  //                     this.result.recording.transcript = null;
  //                     this.popupManager.closeOpenModal(null);
  //                 }
  //                 else {
  //                     console.error('Error with deleteRecordingAndTranscript.');
  //                 }
  //             },
  //             () => { // handle error
  //                 console.error('Error with deleteRecordingAndTranscript:');
  //             });
  //     }
  // });
  // }

  //   parsedTranscript(result) {
  //     console.log(result);
  //     if(result && result.transcript) {
  //       return result.transcript.replace(
  //         /%HESITATION/g,
  //         environment.hesitationWord
  //       );
  //     }
  //     else {
  //       return null;
  //     }
  // }


  transcriptModal(result) {
    this.modalService.open(result.transcript);
  }

  downloadResults() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);
    let newResults = this.results.map((n) => {
      delete n.result_id;
      delete n.transcript;
      delete n.file_info;
      delete n.game_result_id;
      delete n.updated_at;
      delete n.isSelected;
      delete n.permissions;
      return n;
    })
    csvExporter.generateCsv(newResults);

  }
}
