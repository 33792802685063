// TODO: honey

import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import "rxjs/add/operator/map";
import { User } from "../../models/user";
import { NotificationEventService } from "../core/notification.service";
import { NotificationEvent } from "../../models/notification-event";
import { RolesEnum } from "../../enums/user-role.enum";

export interface UserLoginModel {
  Email: string;
  Password: string;
}

@Injectable()
export class AuthService {
  constructor(
    private apiService: ApiService,
    private notificationService: NotificationEventService
  ) {}

  login(username: string, password: string) {
    var userLoginModel = {
      email: username,
      password: password
    };

    return this.apiService
      .post("/auth/login", JSON.stringify(userLoginModel))
      .map(response => {
        // login successful if there's a jwt token in the response
        let responseObj = JSON.parse(JSON.stringify(response));
        let resp = responseObj.response;

        if (resp && resp.access_token) {
          this.setUser(resp);
        }
        return responseObj;
      });
  }


  forgotPassword(email: string) {
    return this.apiService
      .post("/user/password/forgot-password", JSON.stringify({ email: email }))
      .map(response => {
        return JSON.parse(JSON.stringify(response));
      });
  }

  logout() {
    // remove user from local storage to log user out
    this.setUser(null);
    localStorage.removeItem("coach-remember-me-message-this-session");
    localStorage.removeItem("coach-opened-this-session");
  }

  register(user: User, logIn: boolean) {
    return this.apiService.post("/auth/register", user).map(response => {
      // login successful if there's a jwt token in the response
      let responseObj = JSON.parse(JSON.stringify(response));
      if (logIn && responseObj.success) {
        let resp = responseObj.response;
        if (resp && resp.access_token) {
          this.setUser(resp);
        }
      }
      return responseObj;
    });
  }

  getToken() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.access_token) {
      return currentUser.access_token;
    }
    return null;
  }


  getLoggedInUser() {
    return JSON.parse(localStorage.getItem("currentUser"));
  }

  getPermissions(): [] {
    const permissions: any = [];

    const currentUser = this.getLoggedInUser();

    currentUser.permissions.forEach(permission => {
      permissions.push(permission.permission);
    });
    
    return permissions;
  }

  protected setUser(user) {
    if (user == null) {
      localStorage.removeItem("currentUser");
    } else {
      localStorage.setItem("currentUser", JSON.stringify(user));
    }
    this.notificationService.sendNotificationEvent(
      new NotificationEvent("current_user_changed", user)
    );
  }

}
