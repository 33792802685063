export const environment = {
  production: false,
  buffaloUrl: "https://web.t3interactive.com",
  ketchupUrl: "https://api.t3interactive.com",
  bbqUrl: "https://dev.bbq.t3interactive.com",
  adminUrl: "https://admin.t3interactive.com",
  awsBucketName: "presentrsecuretesting",
  awsCognitoIdentity: "us-east-1:0e344ecc-189e-4cf5-aebb-fb41c2596d5f",
  awsAudioFolder: "audios",
  awsVideoFolder: "videos",
  hesitationWord: "um/uh",
  webSocketUrl: "https://dev.mustard.t3interactive.com",
  awsBucketSecureName: "presentrsecuretesting",
  awsBucketPublicName: "t3interactive.com",
  cdnUrl: "https://s3.amazonaws.com/t3interactive.com/",
  secureCdnUrl: "https://test.secure.cdn.presentr.me/",

  stripePublicKey: "pk_test_sWIxCM2ruXTw8n1MPwBXr6hV00WlBJggVm"


};
