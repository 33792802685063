import {
  Component,
  isDevMode,
  OnInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../classes/services/data/auth.service";
import { HasPermissionPipe } from "src/app/classes/pipes/has-permisison.pipe";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  logoColor = isDevMode() ? "RED" : "BLUE";

  loading: Boolean = false;
  error: String = "";
  model: any = {};
  constructor(
    private route: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe,
    private authService: AuthService,
    private router: Router    ) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams["logout"]) {
      this.authService.logout();
      this.router.navigate(['/login']);
    }

    if (this.authService.getLoggedInUser()) {
      this.router.navigate(['/dashboard']);
    }
  }

  login() {
    this.loading = true;
    this.authService
      .login(this.model.username, this.model.password)
      .subscribe(
        response => {
          if (response.success) {
            this.loading = false;

            if(this.hasPermissionPipe.transform('IsAdmin')) {
              this.router.navigate(['/dashboard']);
            }
            else {
              this.error = "Not an admin account.";
              }
          } else {
            this.loading = false;
            this.error = response.response.error;
          }
        },
        error => {
          this.loading = false;
        }
      );
  }

}
