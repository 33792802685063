import { Injectable, Injector } from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../app/classes/services/data/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private http: HttpClient;
  private token: string;

  constructor(private handler: HttpBackend, private injector: Injector) {
    this.http = new HttpClient(handler);
    const authService: AuthService = this.injector.get(AuthService);
    this.token = authService.getToken();
  }

  uploadFile(uploadInfo){
    const formData: FormData = new FormData();

    formData.append('folder', (uploadInfo.isAudioOnly) ? environment.awsAudioFolder : environment.awsVideoFolder);
    formData.append('title', uploadInfo.title || null);
    formData.append('tags',JSON.stringify(uploadInfo.tags) || null);
    formData.append('record_date',uploadInfo.record_date || null);
    formData.append('keywords',JSON.stringify(uploadInfo.keywords) || null);
    formData.append('file', uploadInfo.file, uploadInfo.file.name);

    return this.http.post(`${environment.ketchupUrl}/content/uploadFile`, formData, {headers: {'Authorization': 'Bearer ' + this.token}});

  }

  getCustomTags():string[] {
    let tags = JSON.parse(localStorage.getItem('customTags'))
    return tags;
  }

  addCustomTag(tag) {
    let tags = this.getCustomTags();
    if(!tags) tags = [];

    tags.push(tag.name);
    localStorage.setItem('customTags', JSON.stringify(tags));
  }

  getTagList() {

    return new Promise((resolve, reject) => {
      let customTags = this.getCustomTags();

      let tags = [
        'Ted Talk',
        'YouTube',
        'Country Leader/President',
        'Activist',
        'CEO',
        'Investor Pitch',
        'Large Audience',
        'Medium Audience',
        'Small Audience',
        'Informal Event',
        'Formal Event',
        'Business Event/Work Conference',
        'Wedding Event',
        'Teaching Event',
        'Spiritual/Religious Event'
      ]

      if(customTags) {
        tags = tags.concat(customTags);
      }

      let allTags = tags.map(tag => {
        return {name: tag, selected: false};
      });

      return resolve(allTags);
    });
  }
}
