import {Component, OnInit, NgModule, ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from 'src/app/classes/services/data/content.service';
import { AwsUploaderService } from 'src/app/classes/services/core/aws-uploader.service';
import { FilterPipe } from 'src/app/classes/pipes/filter.pipe';
import { FormsModule } from "@angular/forms"
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { UploadService } from 'src/app/upload.service';


@Component({
  selector: 'app-upload-add',
  templateUrl: './upload-add.component.html',
  styleUrls: ['./upload-add.component.scss']
})

@NgModule({
  imports: [FormsModule],
  declarations: [FilterPipe],
})

export class UploadAddComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  maxSize: number = 300000000;
  showError = false;
  errorMessage = ""
  selectedFile: File = null;
  uploadComplete = false;
  processing = false;
  fileFormat: number; //used in UI for filtering audio or video
  successTitle: string;
  successDescription: string;

  tags:any = [];
  selectedTags = [];
  searchText: string = "";
  fileTitle: string = "";
  recordingDate: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private uploadService: UploadService,
  ) {
  }

  ngOnInit() {
    this.uploadService.getTagList().then(data => {
      this.tags = data;
    });
  }

  upload() {

    this.processing = true;
    const file = this.selectedFile;

   let uploadInfo = {
      title: this.fileTitle,
      file: file,
      isAudioOnly: (this.fileFormat === 1) ? true : false,
      keywords: {
        filler_words:['actually', 'basically', 'like', 'listen', 'literally', 'look', 'so', 'totally', 'um/uh'],
        power_words: []
      },
      record_date: this.recordingDate, //recDate.nativeElement.value, //file.lastModified.toString(),
      tags: this.selectedTags.map(tag => {
        if(tag.selected) return tag.name;
      })
    }

    this.uploadService.uploadFile(uploadInfo).subscribe(
      data => {
        /*code: 200
        options: null
        response:
        data:
        audio_path: null
        created_at: "2021-01-13 17:30:30"
        id: 211
        keywords: "{"filler_words":["actually","basically","like","listen","literally","look","so","totally","um/uh"],"power_words":[]}"
        record_date: "null"
        result_id: 198
        tags: "["Informal Event","Formal Event"]"
        title: "big-buck-bunny.webm"
        updated_at: "2021-01-13 17:30:30"
        video_path: "videos/oGQJ92fCTxtbR7iAHwBpmFj3UmejuIQScMgSEuhu.webm"
        __proto__: Object
        message: "File Uploaded"
        __proto__: Object
        success: true
        */
        if(data.hasOwnProperty('success')) {
          this.successTitle = "Success!";
          this.successDescription = "Your file was uploaded successfully and was added to the queue for processing.";
        }
        else {
          this.successTitle = "Upload Failed"
          this.successDescription = "There was a problem accepting or uploading your file. Please try again or choose another file.";
        }

        //console.log(data);
        this.selectedFile = null;
        this.processing = false;
        this.uploadComplete = true;
        // this.srcInput.nativeElement.value = '';
        this.fileTitle = '';
        this.recordingDate = '';
        this.clearSelection();
      },
      err => {
        //console.error('upload failed!!!!!!');
        this.selectedFile = null;
        this.processing = false;
        this.uploadComplete = true;
        // this.srcInput.nativeElement.value = '';

        this.successTitle = "Upload Failed"
        this.successDescription = "There was a problem accepting your file. Please try again or choose another file.";
      });
  }

  selectFile(event) {
    this.uploadComplete = false;
    this.selectedFile = event.target.files[0];

    if(this.selectedFile.size > this.maxSize) {
      event.target.value = '';
      this.showError = true;
      this.errorMessage = "File too large. Maximum file size should be no larger than " + Math.round(this.maxSize/1024/1024) + "MB.";
      this.selectedFile = null;
    }
    else {
      this.showError = false;
      this.errorMessage = "";
      if(this.fileTitle == "") {
        this.fileTitle = this.selectedFile.name;
      }
    }
  }

  selectItem(event, item) {
    item.selected = (event.target.checked);
    this.getSelected();
  }

  getSelected() {
    //console.log('getSelected()');
    this.selectedTags = this.tags.filter(tag => {
      return tag.selected == true;
    });
  }

  clearFilter() {
    //console.log('clearFilter()');
    this.searchText = "";
  }

  clearSelection() {
    //console.log('clearSelection()');
    this.fileInput.nativeElement.value = '';
    this.searchText = "";
    this.tags = this.tags.filter(tag => {
      tag.selected = false;
      return true;
    });

    this.getSelected();
  }

  addTag() {
    //console.log('addTag()');

    let item = this.tags.find(item => {
      return item.name===this.searchText
    });

    if(item) {
      item.selected = true;
      this.clearFilter();
      return;
    }

    let tag = {
      name: this.searchText,
      selected: true
    }
    this.tags.push(tag);
    this.clearFilter();

    this.uploadService.addCustomTag(tag);
  }

}
