import {Injectable} from '@angular/core';
import * as AWS from 'aws-sdk';
import {environment} from "../../../../environments/environment";
import {NotificationEventService} from "./notification.service";
import {NotificationEvent} from "../../models/notification-event";
import {DebugConsole} from "../../helpers/debug-console";
import { Bool } from 'aws-sdk/clients/clouddirectory';

@Injectable()
export class AwsUploaderService {

	static readonly FILE_UPLOADED_EVENT = "FILE_UPLOADED_EVENT";


    publicBucketName = environment.awsBucketPublicName;
    secureBucketName = environment.awsBucketSecureName;
	cdnUrl = environment.cdnUrl;
	secureCdnUrl = environment.secureCdnUrl;
	envFolderPath = environment.awsAudioFolder;

	constructor(private notificationService:NotificationEventService) {
		AWS.config.region = 'us-east-1'; // Region
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: environment.awsCognitoIdentity,
		});
	}

	subscribeForNotification(scope, callback){
		this.notificationService.subscribeToNotificationEvent(AwsUploaderService.FILE_UPLOADED_EVENT, scope, callback);
	}

	unsubscribeNotification(scope){
		this.notificationService.unsubscribeToNotificationEvent(AwsUploaderService.FILE_UPLOADED_EVENT, scope);
	}

	uploadFile(file, folderPath = null, isSecure: Bool = false): string {

		if(!folderPath){
            folderPath = this.envFolderPath;
		}
		let fileKey = folderPath.concat(file.name);

        DebugConsole.log("Upload File", fileKey, folderPath, isSecure);
		(AWS.config.credentials as AWS.Credentials).get( (err) => {
			if(!err){
				let s3 = new AWS.S3();

				let scope = this;
				s3.upload({
					Bucket: (isSecure ? this.secureBucketName : this.publicBucketName),
					Key: fileKey,
					Body: file,
					ACL: isSecure ? '' : 'public-read'
				}, function(err, data) {
					if (err) {
						DebugConsole.log('There was an error uploading your file: ', err.message);
					}
					else{
						DebugConsole.log('Successfully uploaded file.', data);
						scope.notificationService.sendNotificationEvent(new NotificationEvent(AwsUploaderService.FILE_UPLOADED_EVENT, data));
					}
				});
			}
			else{
				DebugConsole.log("There was an error getting cognito credentials ", err.message);
			}
		});
		return (isSecure ? this.secureCdnUrl : this.cdnUrl) + fileKey;
	}
}
