import { Routes, RouterModule } from '@angular/router';
import { JobStatusComponent } from '../pages/job-status/job-status.component';

export const content: Routes = [
  {
    path: '',
    loadChildren: 'src/app/pages/general/general.module#GeneralModule'
  },
  {
    path: 'job/:id',
    component: JobStatusComponent
  },
  {
    path: 'content',
    loadChildren: 'src/app/pages/content/content.module#ContentModule'
  },
  {
    path: 'reports',
    loadChildren: 'src/app/pages/reports/reports.module#ReportsModule'
  },
  {
    path: 'user',
    loadChildren: 'src/app/pages/users/users.module#UsersModule'
  },
  {
    path: 'customer',
    loadChildren: 'src/app/pages/customers/customers.module#CustomersModule'
  },
  {
    path: 'event',
    loadChildren: 'src/app/pages/events/events.module#EventsModule'
  },
  {
    path: 'results',
    loadChildren: 'src/app/pages/results/results.module#ResultsModule'
  },
  {
    path: 'legacy',
    loadChildren: 'src/app/pages/legacy/legacy.module#LegacyModule'
  },
];
