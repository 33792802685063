import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from 'src/app/classes/services/data/api.service';

@Component({
  selector: 'app-job-status',
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.scss']
})
export class JobStatusComponent implements OnInit, AfterViewInit, OnDestroy {

  id: String;
  redirectTo: String;
  job: any = {};
  refreshHandle: any;
  redirectHandle: any;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute) {

    this.route.paramMap.subscribe(params => {
      this.id = this.route.snapshot.paramMap.get('id');
      this.redirectTo = this.route.snapshot.queryParamMap.get('redirectTo');
    });
  }

  ngOnInit() {
    // this.refreshStatus();
  }

  ngAfterViewInit(): void {
    this.refreshInterval();
  }

  private refreshInterval(){
    this.refreshStatus();
    this.refreshHandle = setTimeout( () => {
      if(this.job && this.job.count && this.job.count.pending && this.job.count.pending > 0){
        this.refreshInterval();
      }
      else{
        this.redirectHandle = setTimeout( () => {
          // we finished the job, automatically redirect after 30 seconds
          this.moveForward();
        }, 30000);
      }
    }, 5000);
  }

  get totalTaskNumber() {
    return this.job ? this.job.count.completed + this.job.count.pending + this.job.count.failed : 0;
  }

  get completedTaskNumber() {
    return this.job ? this.job.count.completed + this.job.count.failed : 0;
  }

  refreshStatus() {
    this.apiService.get('/job/' + this.id).subscribe(resp => {
      let response = JSON.parse(JSON.stringify(resp));
      this.job = response.response;
        // console.log(this.totalTaskNumber, this.completedTaskNumber,  (this.totalTaskNumber > 0 ? this.completedTaskNumber * 100 / this.totalTaskNumber : 0).toString() + "%");
      setTimeout(() =>{
        let bar = document.getElementById('progress-bar');
        if (bar) {
          bar.style.width = (this.totalTaskNumber > 0 ? this.completedTaskNumber * 100 / this.totalTaskNumber : 0).toString() + "%";
        }
      }, 250);

    });
  }


  moveForward() {
    this.clearHandles();
    this.router.navigate([this.redirectTo]);
  }

  private clearHandles(){
    clearTimeout(this.redirectHandle);
    clearTimeout(this.refreshHandle);
  }
  ngOnDestroy(): void {
    this.clearHandles();
  }
}
