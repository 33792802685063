import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";

// Components
import { ContentComponent } from "./layouts/content/content.component";
import { LoginComponent } from "./pages/authentication/login/login.component";

import { AdminGuard } from './classes/guards/admin.guard';

import { content } from "./shared/content.routes";
import { UploadDataComponent } from "./pages/upload/upload-data/upload-data.component";
import { UploadAddComponent } from "./pages/upload/upload-add/upload-add.component";
import {NgbdModalBasic} from "./pages/upload/upload-data/modal-basic";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
},
{
    path: 'logout',
    redirectTo: '/login?logout=true',
    pathMatch: 'full'
},
{
  path: 'add-ons',
  canActivate: [AdminGuard],
  loadChildren:  'src/app/add-ons/add-ons.module#AddOnsModule'
},
  {
    path: 'modal-basics',
    component: NgbdModalBasic
  },
{
  path: 'upload-data',
  component: UploadDataComponent
},
{
  path: 'upload-add',
  component: UploadAddComponent
},
{
  path: '',
  component: ContentComponent,
  canActivate: [AdminGuard],
  children: content
},
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
