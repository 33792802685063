import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import * as $ from "jquery";
import { FormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { ContentComponent } from "./layouts/content/content.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { SidebarComponent } from "./shared/menu/sidebar/sidebar.component";
import { ToggleFullscreenDirective } from "./shared/directives/fullscreen.directive";
import { LoginComponent } from "./pages/authentication/login/login.component";

import { AdminGuard } from "./classes/guards/admin.guard";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./classes/interceptors/token.interceptor";
import { AuthService } from "./classes/services/data/auth.service";
import { ApiService } from "./classes/services/data/api.service";
import { NotificationEventService } from "./classes/services/core/notification.service";
import { ContentService } from "./classes/services/data/content.service";
import { AwsUploaderService } from "./classes/services/core/aws-uploader.service";
import { SettingsService } from "./classes/services/data/settings.service";
import { HasPermissionPipe } from "./classes/pipes/has-permisison.pipe";
import { JobStatusComponent } from './pages/job-status/job-status.component';
import { NgpSortModule } from 'ngp-sort-pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FilterPipe } from 'src/app/classes/pipes/filter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBasic } from './pages/upload/upload-data/modal-basic';
import { UploadDataComponent } from "./pages/upload/upload-data/upload-data.component";
import { UploadAddComponent } from "./pages/upload/upload-add/upload-add.component";
import { TagFilterPipe } from './filters/tag-filter.pipe';
// import {MediaPopupModalComponent} from './pages/upload/upload-data/media-popup-modal.component';


@NgModule({
  entryComponents: [
    NgbdModalBasic,
    UploadAddComponent,
    UploadDataComponent,
    // MediaPopupModalComponent,
  ],
  declarations: [
    AppComponent,
    ContentComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    LoginComponent,
    HasPermissionPipe,
    JobStatusComponent,
    FilterPipe,
    NgbdModalBasic,
    UploadDataComponent,
    UploadAddComponent,
    TagFilterPipe,
    // MediaPopupModalComponent,
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgpSortModule,
    NgMultiSelectDropDownModule,
    NgbModule,
    ],
  providers: [
    HasPermissionPipe,
    AwsUploaderService,
    AdminGuard,
    AuthService,
    ApiService,
    ContentService,
    NotificationEventService,
    SettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
