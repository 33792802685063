import {
  Component,
  isDevMode,
  OnInit,
} from "@angular/core";
import * as $ from 'jquery';


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
